import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Form from '../components/form'

import kv from '../images/foragencies-kv.jpg'

const ForAgencies = ({ location }) => (
  <Layout location={location} title="For" title2="Agencies" subTitle="代理店募集" kv={kv}>
    <SEO title="代理店募集" />
    <section className="contact">
      <h2>募集条件</h2>
      <section className="information apply">
        <dl>
          <dt>募集形態</dt>
          <dd>法人</dd>
        </dl>
        <dl>
          <dt>ビジネス概要</dt>
          <dd>
            個人営業：エンドユーザー様へのご提案<br />
            法人営業：不動産会社や工務店への業務提携の交渉
          </dd>
        </dl>
        <dl>
          <dt>給与等</dt>
          <dd>経験等による為、お問い合わせ下さい</dd>
        </dl>
        <dl>
          <dt>募集エリア</dt>
          <dd>全国</dd>
        </dl>
        <dl>
          <dt>事業内容</dt>
          <dd>
          太陽光発電システム及び蓄電池システム等の<br />卸売・販売・施工・メンテナンス
          </dd>
        </dl>
      </section>
    </section>
    <section className="global-form">
      <h2>応募フォーム</h2>
      <Form from="代理店" />
    </section>
  </Layout>
)

export default ForAgencies
